import React from 'react';

const Position = () => {
    return (
        <div className="posi">
        <div className="position">
            ORACLE APEX DEVELOPER

        </div>
            <hr/>
        </div>
    );
};

export{Position};