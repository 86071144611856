import React from 'react';

const Photo = () => {
    return (
        // <div>
            <img className="pho" src="YarKh.png" alt="image"/>
        // </div>
    );
};

export {Photo};