import React from 'react';

const Name = () => {
    return (
        <div className="FIO">
            <div className="Name">YAROSLAV</div>
            <div className="Last">KHOTINENKO</div>
        </div>
    );
};

export {Name};