import './App.css';
import {Cv} from './components/cv/Cv'

function App() {
  return (
    // <div className="App">
    //    <cv/>
    // </div>
      <Cv/>
  );
}

export {App};
