import React from 'react';

const Action = () => {
    return (
        <div className='action'>
            I love solving problems and bringing projects to completion.
        </div>
    );
};

export
{Action};